import * as React from "react";

export default function Logout() {

  React.useEffect(() => {



  }, []);

  return (
    <>
    </>
  );
}
