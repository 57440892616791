import { atom } from "recoil";

//ログイン情報
export const loginInfoAtom = atom({
  key: "loginInfoAtom",
  default: {},
});

//メニュー現在地
export const currentMenuAtom = atom({
  key: "currentMenuAtom",
  default: "creator",
});

//ローディング状態
export const showLoaderAtom = atom({
  key: "showLoaderAtom",
  default: false,
});
