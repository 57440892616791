import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  TextField,
} from "@mui/material";
import { forwardRef } from "react";

function UrlDialog({ open = false, setOpen, urlInfos = [],title }) {
  //   const Transition = forwardRef(function Transition(props, ref) {
  //     return <Slide direction="down" ref={ref} {...props} />;
  //   });

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>

          {urlInfos.map((urlInfo) => (
            <Grid container justify="center" spacing={1} sx={{ mt: "5px" }} key={urlInfo.url + urlInfo.label}>
              <Grid item xs={10}>
                <TextField
                  id="outlined-read-only-input"
                  label={urlInfo.label}
                  fullWidth
                  defaultValue={urlInfo.url}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{ mt: "12px", minWidth:"lg" }}
                />
              </Grid>
              <Grid item xs={2}>
               <img
                src={
                  "https://api.qrserver.com/v1/create-qr-code/?data=" +
                  encodeURIComponent(urlInfo.url) + '&size=100x100'
                }
              />
              </Grid>
            </Grid>
          ))}
          <DialogActions>
        </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UrlDialog;
