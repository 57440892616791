import axios from "axios";
import { Navigate } from "react-router-dom";

export function isset(val) {
  return typeof val != "undefined";
}

const apiRunnings = [];

export function api({ type = "get", url = "", data = {} }) {
  return new Promise((resolve, reject) => {
	  
	 const runsKey = url + ":" + type;

    //多重送信防止
    if (apiRunnings.indexOf(runsKey) !== -1) {
      console.dir("skip");
      return;
    }
    apiRunnings.push(runsKey);
    
    axios.defaults.headers.common = {
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };

    let axios_ret = null;

    if (type === "post") {
      axios_ret = axios.post(url, data, { withCredentials: true });
    } else if (type === "put") {
      axios_ret = axios.put(url, data, { withCredentials: true });
    } else if (type === "delete") {
      axios_ret = axios.delete(url, { withCredentials: true });
    } else {
      axios_ret = axios.get(url, { withCredentials: true });
    }

    axios_ret

      .then((response) => {
        console.dir(response);
        
        const runIndex = apiRunnings.indexOf(runsKey);
        if (runIndex) {
          apiRunnings.splice(runIndex, 1);
        }

        if (isset(response.data.csrfToken) && response.data.csrfToken) {
          document
            .querySelector('meta[name="csrf-token"]')
            .setAttribute("content", response.data.csrfToken);
        }

        //認証エラー
        if (!isset(response.data.auth) || response.data.auth !== true) {
          if (
            isset(response.data.authEscape) &&
            response.data.authEscape === true
          ) {
          } else {
            window.location.href = "/login";
          }
        }

        ///

        resolve(response);
      })
      .catch((error) => {
        console.dir(error);
        
        const runIndex = apiRunnings.indexOf(runsKey);
        if (runIndex) {
          apiRunnings.splice(runIndex, 1);
        }

        //認証エラー
        if (isset(error.response.status) && error.response.status === 401) {
          window.location.href = "/login";
        }
        else if (isset(error.response.status) && error.response.status === 400) {
          window.location.href = "/err01";
        }
        else {
        ///

        //todo 開発中はコメント
          window.location.href = "/err00";
        }
        return;

        // reject(error);
      });
  });
}
