import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import { forwardRef } from "react";

function ConfirmDialog({
  open = false,
  okFunc,
  closeFunc,
  titleText = "",
  bodyText = "",
}) {
  //   const Transition = forwardRef(function Transition(props, ref) {
  //     return <Slide direction="down" ref={ref} {...props} />;
  //   });

  return (
    <>
      <Dialog
        open={open}
        onClose={closeFunc}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {titleText !== "" && <DialogTitle>{titleText}</DialogTitle>}

        <DialogContent>
          {bodyText !== "" && (
            <DialogContentText id="alert-dialog-slide-description">
              {bodyText}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeFunc}
          >
            CANCEL
          </Button>
          <Button onClick={okFunc}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmDialog;
