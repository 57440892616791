import { Close, Delete, Done } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { api, isset } from "../util/util";
import { API_CONNECTION, JA_LANG } from "../config/Const";
import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../component/ConfirmDialog";
import SubscriptionList from "./SubscriptionList";

function FanEditor({ closeFunc, fanId = 0, handleChangePage, fanListPage }) {
  const { enqueueSnackbar } = useSnackbar();

  let isEdit = fanId !== 0;

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [showConfirm, setShowConfirm] = useState(false);

  const [formData, setFormData] = useState({
    id: 0,
    nickname: "",
    email: "",
    password: "",
    created_at: "",
  });

  const [listData, setListData] = useState({});

  const [errorList, setErrorList] = useState({});

  //初期処理
  useEffect(() => {
    isEdit && setShowLoader(true);
    api({ url: `${API_CONNECTION.URL}api/fans/${fanId}` })
      .then((res) => {
        if (isEdit) {
          setFormData(res.data.fan);
        }
        initListData(res.data);
        setShowLoader(false);

        console.dir(listData);
      })
      .catch((err) => {
        setShowLoader(false);
      });

    isEdit = fanId !== 0;
  }, [fanId]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleDelete = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});
    setShowConfirm(false);

    setShowLoader(true);

    api({
      url: `${API_CONNECTION.URL}api/fans/${formData.id}`,
      type: "delete",
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          if (handleChangePage) {
            handleChangePage(null, fanListPage ? fanListPage : 0);
          }
          closeFunc();
          enqueueSnackbar("Fan delete successfully!", {
            variant: "success",
          });
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  return (
    <Fragment key={fanId}>
      <AppBar sx={{ position: "relative" }} color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeFunc}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {fanId ? "Fan : " + formData.nickname : "Regist Fan"}
          </Typography>

          {isEdit && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Delete />}
              size="small"
              sx={{ ml: "10px" }}
              onClick={() => {
                setShowConfirm(true);
              }}
            >
              Delete
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container maxWidth="100%" component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <input type="hidden" name="id" value="{formData.id}" />
            <Grid container justify="center" spacing={3}>
              <Grid item xs={12} md={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <FormLabel>Nickname</FormLabel> */}
                    <InputLabel shrink>Nickname</InputLabel>
                    {formData.nickname}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink>Email</InputLabel>
                    {formData.email}
                  </Grid>
                  {isEdit && (
                    <>
                      <Grid item xs={12}>
                        <InputLabel shrink>Birthday</InputLabel>
                        {formData.birthday}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel shrink>CODE</InputLabel>
                        {formData.code}
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel shrink>Created at</InputLabel>
                        {formData.created_at}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              {isEdit && (
                <Grid item xs={12} md={9}>
                  <SubscriptionList fanId={fanId} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Container>
      <ConfirmDialog
        open={showConfirm}
        okFunc={handleDelete}
        closeFunc={(e) => {
          setShowConfirm(false);
        }}
        titleText={JA_LANG.CONFIRM_DELETE_TITLE}
        bodyText={JA_LANG.CONFIRM_USER_DELETE_BODY}
      />
    </Fragment>
  );
}

export default FanEditor;
