import { Close, Delete, Done } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { api, isset } from "../util/util";
import { API_CONNECTION, JA_LANG } from "../config/Const";
import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../component/ConfirmDialog";

function BranchEditor({ closeFunc, branchId = 0, handleChangePage }) {
  const { enqueueSnackbar } = useSnackbar();

  let isEdit = branchId !== 0;

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [showConfirm, setShowConfirm] = useState(false);

  const [formData, setFormData] = useState({
    id: 0,
    name: "",
    zip: "",
    pref: "",
    addr01: "",
    addr02: "",
    addr03: "",
    new_production: "1",
    production_name: "",
    production_id: 0,
  });

  const [listData, setListData] = useState({});

  const [errorList, setErrorList] = useState({});

  //初期処理
  useEffect(() => {
    isEdit && setShowLoader(true);
    api({ url: `${API_CONNECTION.URL}api/productions/branch/${branchId}` })
      .then((res) => {
        if (isEdit) {
          res.data.branch.new_production = "0";
          setFormData(res.data.branch);
        }
        initListData(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }, [branchId]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});

    setShowLoader(true);

    let apiUrl = `${API_CONNECTION.URL}api/productions/branch`;
    let apiMethod = "post";

    if (isEdit) {
      apiUrl = `${API_CONNECTION.URL}api/productions/branch/${formData.id}`;
      apiMethod = "put";
    }

    api({
      url: apiUrl,
      type: apiMethod,
      data: formData,
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          if(handleChangePage) {
            handleChangePage(null);
          }
          closeFunc();
          enqueueSnackbar(
            isEdit
              ? "Branch update successfully!"
              : "Branch regist successfully!",
            {
              variant: "success",
            }
          );
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleDelete = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});
    setShowConfirm(false);

    setShowLoader(true);

    api({
      url: `${API_CONNECTION.URL}api/productions/branch/${formData.id}`,
      type: "delete",
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          if(handleChangePage) {
            handleChangePage(null);
          }
          closeFunc();
          enqueueSnackbar("Branch delete successfully!", {
            variant: "success",
          });
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleChangeZip = () => {
    const { AjaxZip3 } = window;
    AjaxZip3.zip2addr("zip", "", "pref", "addr01", "addr02", "addr02");

    AjaxZip3.onSuccess = () => {
      const autoInput = {};
      autoInput["pref"] = document.getElementById("pref").value;
      autoInput["addr01"] = document.getElementById("addr01").value;
      autoInput["addr02"] = document.getElementById("addr02").value;

      setFormData({ ...formData, ...autoInput });
    };
  };

  return (
    <Fragment key={branchId}>
      <AppBar sx={{ position: "relative" }} color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeFunc}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {branchId
              ? "Branch : " + formData.name
              : "Regist Production Branch"}
          </Typography>

          <Button
            autoFocus
            color="secondary"
            variant="contained"
            startIcon={<Done />}
            onClick={handleSubmit}
          >
            SAVE
          </Button>
          {isEdit && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Delete />}
              size="small"
              sx={{ ml: "10px" }}
              onClick={() => {
                setShowConfirm(true);
              }}
            >
              Delete
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <input type="hidden" name="id" value={formData.id} />
            <Grid container justify="center" spacing={10}>
              <Grid item xs={12} md={12}>
                <Alert
                  variant="outlined"
                  severity="error"
                  className="error-alert"
                  style={{
                    display: isset(errorList.process) ? "" : "none",
                  }}
                  sx={{ mb: "20px" }}
                >
                  {errorList.process}
                </Alert>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel required shrink htmlFor="new_production">
                        Create production
                      </InputLabel>
                      <RadioGroup
                        id="new_production"
                        row
                        aria-labelledby="radio_group_new_production"
                        name="new_production"
                        onChange={handleForm}
                        value={formData.new_production}
                        sx={{ width: "100%" }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {formData.new_production === "1" && (
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="production_name"
                        label="Production Name"
                        name="production_name"
                        value={formData.production_name}
                        onChange={handleForm}
                      />
                      <Alert
                        variant="outlined"
                        severity="error"
                        className="error-alert"
                        style={{
                          display: isset(errorList.production_name)
                            ? ""
                            : "none",
                        }}
                        sx={{ mt: "5px" }}
                      >
                        {errorList.production_name}
                      </Alert>
                    </Grid>
                  )}
                  {formData.new_production === "0" && (
                    <Grid item xs={12}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel required shrink htmlFor="production_id">
                          Production id
                        </InputLabel>

                        <Select
                          native
                          id="production_id"
                          label="Production id"
                          name="production_id"
                          value={formData.production_id}
                          onChange={handleForm}
                        >
                          <option aria-label="" value="">
                            ---
                          </option>
                          {isset(listData.arrProduction) &&
                            Object.keys(listData.arrProduction).map(
                              (productionKey) => (
                                <option
                                  value={productionKey}
                                  key={productionKey}
                                >
                                  {listData.arrProduction[productionKey]}
                                </option>
                              )
                            )}
                        </Select>
                      </FormControl>
                      <Alert
                        variant="outlined"
                        severity="error"
                        className="error-alert"
                        style={{
                          display: isset(errorList.production_id) ? "" : "none",
                        }}
                        sx={{ mt: "5px" }}
                      >
                        {errorList.production_id}
                      </Alert>
                    </Grid>
                  )}
                  <hr />
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="name"
                      label="Branch Name"
                      name="name"
                      value={formData.name}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.name) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.name}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="tel"
                      id="phone"
                      label="Tel"
                      name="phone"
                      value={formData.phone}
                      inputProps={{ maxLength: 11, pattern: "^0[0-9]{8,10}$" }}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{ display: isset(errorList.phone) ? "" : "none" }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.phone}
                    </Alert>
                  </Grid>
                  <hr />
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="tel"
                      id="zip"
                      label="Zip Code"
                      name="zip"
                      value={formData.zip}
                      inputProps={{ maxLength: 7, pattern: "^[0-9]{7}$" }}
                      onChange={handleForm}
                      onKeyUp={handleChangeZip}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{ display: isset(errorList.zip) ? "" : "none" }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.zip}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: "100%" }}>
                      <InputLabel required shrink htmlFor="pref">
                        Prefecture
                      </InputLabel>

                      <Select
                        native
                        id="pref"
                        label="Prefecture"
                        name="pref"
                        value={formData.pref}
                        onChange={handleForm}
                      >
                        <option aria-label="" value="">
                          ---
                        </option>
                        {isset(listData.arrPref) &&
                          Object.keys(listData.arrPref).map((prefKey) => (
                            <option value={prefKey} key={prefKey}>
                              {listData.arrPref[prefKey]}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.pref) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.pref}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="addr01"
                      label={JA_LANG.ADDR01}
                      name="addr01"
                      value={formData.addr01}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.addr01) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.addr01}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="addr02"
                      label={JA_LANG.ADDR02}
                      name="addr02"
                      value={formData.addr02}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.addr02) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.addr02}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="addr03"
                      label={JA_LANG.ADDR03 + JA_LANG.NOTICE_ADDR03}
                      name="addr03"
                      value={formData.addr03}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.addr03) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.addr03}
                    </Alert>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        okFunc={handleDelete}
        titleText={JA_LANG.CONFIRM_DELETE_TITLE}
      />
    </Fragment>
  );
}

export default BranchEditor;
