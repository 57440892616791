import { DotLoaderOverlay } from "react-spinner-overlay";
import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom";

function Loader() {
  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  return <>
    
    <DotLoaderOverlay loading={showLoader} size={15} zIndex={3000} style={{
      transition: '1s',
      opacity: showLoader ? 1 : 0,
      visibility: showLoader ? 'visible' : 'hidden'
    }}/>
  
  </>;
}

export default Loader;
