import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { API_CONNECTION } from "../config/Const";

// ページのコンポーネント
import RouterApp from "../RouterApp";
import Account from "../page/Account";
import Fan from "../page/Fan";
import Creator from "../page/Creator";
import Production from "../page/Production";
import Login from "../page/Login";
import { api } from "../util/util";
import Error01 from "../page/error/Error01";
import Error00 from "../page/error/Error00";
import Logout from "../page/Logout";

const routerLink = createBrowserRouter(
  
  createRoutesFromElements(
    <>
      <Route path="/" element={<RouterApp />} loader={() => {return api({ url: `${API_CONNECTION.URL}api/user` })}}>
        <Route path="creator" element={<Creator />} loader={() => {return api({ url: `${API_CONNECTION.URL}api/creators?page=0` }); }}/>
        <Route path="fan" element={<Fan />} loader={() => {return api({ url: `${API_CONNECTION.URL}api/fans?page=0` }); }} />
        <Route path="production" element={<Production />} loader={() => {return api({ url: `${API_CONNECTION.URL}api/productions` }); }}  />
        <Route path="account" element={<Account />} loader={() => {return api({ url: `${API_CONNECTION.URL}api/accounts?page=0` }); }} />
        <Route path="/logout" element={<Logout />}  loader={() => {return api({ url: `${API_CONNECTION.URL}api/logout` }).then(() => {window.location.href = '/login';}); }}/>
      </Route>
      <Route path="/login" element={<Login />} />
      
      <Route path="/err01" element={<Error01 />} />
      <Route path="/err00" element={<Error00 />} />
    </>
  )
);

export default routerLink;
