import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Alert, AlertTitle, Link, Stack } from "@mui/material";
import { JA_LANG } from "../../config/Const";

export default function Error00() {
  return (
    <>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">
              <AlertTitle>Error：{JA_LANG.ERR_UNEXPECTED}</AlertTitle>
              {JA_LANG.ERR_LINK}<br />
              <Link href="/creator">BACK</Link>
            </Alert>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
