import React, { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { api, isset } from "../util/util";
import { useRecoilState } from "recoil";
import { currentMenuAtom, showLoaderAtom } from "../store/atom";
import { API_CONNECTION } from "../config/Const";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Container,
  Dialog,
  Fab,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Add, Search, Settings } from "@mui/icons-material";
import AccountEditor from "./AccountEditor";

const columns = [
  { id: "id", label: "ID", align: "left" },
  { id: "name", label: "NAME", align: "left" },
  { id: "email", label: "EMAIL", align: "left" },
  { id: "authority", label: "AUTHORITY", align: "left", list: "arrAuthority" },
  { id: "created_at", label: "CREATED", align: "left" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Account() {
  const navigate = useNavigate();

  const loadData = useLoaderData().data;

  const [currentMenu, setCurrentMenu] = useRecoilState(currentMenuAtom);

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [accountList, setAccountList] = useState([]);
  const [accountCount, setAccountCount] = useState(0);
  const [accountListPage, setAccountListPage] = useState(0);
  const [accountListRows, setAccountListRows] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const [searchInput, setSearchInput] = useState("");

  const [formData, setFormData] = useState({});
  const [errorList, setErrorList] = useState({});

  const [editorOpen, setEditorOpen] = useState(false);

  const [editId, setEditId] = useState(0);

  const [listData, setListData] = useState({});

  const useEffectInit = useRef(false);

  const listSetting = (listdata) => {
    setCurrentMenu("account");
    setAccountList(listdata.accounts);
    setAccountCount(listdata.allCount);
    setAccountListPage(listdata.page);
    setAccountListRows(listdata.rowsPerPage);
    setOrder(listdata.order);
    setOrderBy(listdata.orderBy);
  };

  const isManager = useRef(false);
  const loginId = useRef(0);

  //初期処理
  useEffect(() => {
    setCurrentMenu("account");

    if (
      isset(loadData.searchSet) &&
      isset(loadData.searchSet.searchInput) &&
      loadData.searchSet.searchInput
    ) {
      setSearchInput(loadData.searchSet.searchInput);
    }

    listSetting(loadData);
    initListData(loadData);

    if (isset(loadData.isManager) && isset(loadData.loginId)) {
      isManager.current = loadData.isManager;
      loginId.current = loadData.loginId;
    }
  }, []);

  //検索窓監視
  useEffect(() => {
    if (useEffectInit.current === false) {
      useEffectInit.current = true;
      return;
    }

    const timer = setTimeout(() => {
      const sInputQuery = encodeURI(searchInput);

      setShowLoader(true);
      api({
        url: `${API_CONNECTION.URL}api/accounts?page=0&searchInput=${sInputQuery}`,
      })
        .then((res) => {
          listSetting(res.data);
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    api({ url: `${API_CONNECTION.URL}api/accounts?page=${newPage}` })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((err) => {});
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsVal = parseInt(event.target.value, 10);
    setShowLoader(true);
    api({ url: `${API_CONNECTION.URL}api/accounts?page=0&rows=${rowsVal}` })
      .then((res) => {
        listSetting(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const handleChangeSort = (columnName) => {
    const selected = columnName === orderBy;
    api({
      url: `${API_CONNECTION.URL}api/accounts?page=0&order_by=${columnName}&order=${order}&selected=${selected}`,
    })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((res) => {});
  };

  const handleEditorOpen = (accountId) => {
    setEditId(accountId);
    setEditorOpen(true);
  };

  const handleEditorClose = () => {
    setEditorOpen(false);
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
        <h2>Account</h2>
        {isManager.current === true && (
          <Fab
            size="medium"
            color="secondary"
            aria-label="add"
            sx={{ position: "absolute", top: 80, right: 15 }}
            onClick={() => {
              handleEditorOpen(0);
            }}
          >
            <Add />
          </Fab>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel htmlFor="input-with-icon-adornment">
                Seach Text
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                value={searchInput}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: window.innerHeight }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ fontWeight: "bold" }}
                          sortDirection={orderBy === column.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : "asc"}
                            onClick={() => handleChangeSort(column.id)}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell key="edit" align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {accountList.map((account) => {
                      return (
                        <TableRow hover role="checkbox" key={account.id}>
                          {columns.map((column) => {
                            let value = account[column.id];

                            if (
                              isset(column.list) &&
                              isset(listData[column.list]) &&
                              isset(listData[column.list][value])
                            ) {
                              value = listData[column.list][value];
                            } else if (
                              column.format &&
                              typeof value === "number"
                            ) {
                              value = column.format(value);
                            }
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })}
                          <TableCell key="edit" align="left">
                            {(isManager.current === true ||
                              loginId.current === account.id) && (
                              <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                size="small"
                                onClick={(e) => {
                                  handleEditorOpen(account.id);
                                }}
                                sx={{ textAlign: "center", padding: "0" }}
                              >
                                <Settings />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={loadData.arrPageRowNum}
                component="div"
                count={accountCount}
                rowsPerPage={accountListRows}
                page={accountListPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen
        open={editorOpen}
        onClose={handleEditorClose}
        TransitionComponent={Transition}
      >
        <AccountEditor
          closeFunc={handleEditorClose}
          accountId={editId}
          key={editId} //子を初期化させるために必要
          handleChangePage={handleChangePage}
          accountListPage={accountListPage}
        />
      </Dialog>
    </>
  );
}

/*
function Profile() {
  return <h2>記事2</h2>;
}

*/

export default Account;
