import { Close, Delete, Done } from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { api, isset } from "../util/util";
import { API_CONNECTION, JA_LANG } from "../config/Const";
import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom";
import { useSnackbar } from "notistack";
import ConfirmDialog from "../component/ConfirmDialog";

function AccountEditor({
  closeFunc,
  accountId = 0,
}) {
  const { enqueueSnackbar } = useSnackbar();

  let isEdit = accountId !== 0;

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [showConfirm, setShowConfirm] = useState(false);

  const [formData, setFormData] = useState({
    id: 0,
    name: "",
    email: "",
    password: "",
    authority: "",
    created_at: "",
  });

  const [listData, setListData] = useState({});

  const [errorList, setErrorList] = useState({});

  const isManager = useRef(false);

  //初期処理
  useEffect(() => {
    isEdit && setShowLoader(true);
    api({ url: `${API_CONNECTION.URL}api/accounts/${accountId}` })
      .then((res) => {
        if (isEdit) {
          setFormData(res.data.account);
        }
        if (isset(res.data.isManager)) {
          isManager.current = res.data.isManager;
        }
        initListData(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });

    isEdit = accountId !== 0;
  }, [accountId]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});

    setShowLoader(true);

    let apiUrl = `${API_CONNECTION.URL}api/accounts`;
    let apiMethod = "post";

    if (isEdit) {
      apiUrl = `${API_CONNECTION.URL}api/accounts/${formData.id}`;
      apiMethod = "put";
    }

    api({
      url: apiUrl,
      type: apiMethod,
      data: formData,
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {

          //再認証のためリダイレクト
          window.location.href = "/account";
          closeFunc();
          enqueueSnackbar(
            isEdit
              ? "Account update successfully!"
              : "Account regist successfully!",
            {
              variant: "success",
            }
          );
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleDelete = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});
    setShowConfirm(false);

    setShowLoader(true);

    api({
      url: `${API_CONNECTION.URL}api/accounts/${formData.id}`,
      type: "delete",
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          //再認証のためリダイレクト
          window.location.href = "/account";

          closeFunc();
          enqueueSnackbar("Account delete successfully!", {
            variant: "success",
          });
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  return (
    <Fragment key={accountId}>
      <AppBar sx={{ position: "relative" }} color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeFunc}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {accountId ? "Account : " + formData.name : "Regist Account"}
          </Typography>

          <Button
            autoFocus
            color="secondary"
            variant="contained"
            startIcon={<Done />}
            onClick={handleSubmit}
          >
            SAVE
          </Button>
          {isEdit && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Delete />}
              size="small"
              sx={{ ml: "10px" }}
              onClick={() => {
                setShowConfirm(true);
              }}
            >
              Delete
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <input type="hidden" name="id" value="{formData.id}" />
            <Grid container justify="center" spacing={10}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {isManager.current === true ? (
                      <>
                        <FormControl sx={{ minWidth: "100%" }}>
                          <InputLabel required shrink htmlFor="authority">
                            Authority
                          </InputLabel>

                          <Select
                            native
                            defaultValue=""
                            id="authority"
                            label="Authority"
                            name="authority"
                            value={formData.authority}
                            disabled={!isManager.current}
                            onChange={handleForm}
                          >
                            <option aria-label="" value="">
                              ---
                            </option>
                            {isset(listData.arrAuthority) &&
                              Object.keys(listData.arrAuthority).map(
                                (authorityType) => (
                                  <option
                                    value={authorityType}
                                    key={authorityType}
                                  >
                                    {listData.arrAuthority[authorityType]}
                                  </option>
                                )
                              )}
                          </Select>
                        </FormControl>
                        <Alert
                          variant="outlined"
                          severity="error"
                          className="error-alert"
                          style={{
                            display: isset(errorList.authority) ? "" : "none",
                          }}
                          sx={{ mt: "5px" }}
                        >
                          {errorList.authority}
                        </Alert>
                      </>
                    ) : (
                      <input
                        type="hidden"
                        name="authority"
                        value="{formData.authority}"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      autoComplete="name"
                      value={formData.name}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.name) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.name}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <FormLabel>Email</FormLabel> */}
                    <TextField
                      required
                      fullWidth
                      type="email"
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleForm}
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{ display: isset(errorList.email) ? "" : "none" }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.email}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <FormLabel>Password</FormLabel> */}
                    <TextField
                      required={accountId ? false : true}
                      fullWidth
                      name="password"
                      label={'Password ' + (accountId ? '※' + JA_LANG.ENTER_ONLY_FOR_UPDATE : '')}
                      type="password"
                      id="password"
                      value={formData.password}
                      onChange={handleForm}
                      autoComplete="new-password"
                    />
                    <Alert
                      variant="outlined"
                      severity="error"
                      className="error-alert"
                      style={{
                        display: isset(errorList.password) ? "" : "none",
                      }}
                      sx={{ mt: "5px" }}
                    >
                      {errorList.password}
                    </Alert>
                  </Grid>
                  {isEdit && (
                    <Grid item xs={12}>
                      <InputLabel shrink>Created at</InputLabel>
                      {formData.created_at}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        okFunc={handleDelete}
        titleText={JA_LANG.CONFIRM_DELETE_TITLE}
      />
    </Fragment>
  );
}

export default AccountEditor;
