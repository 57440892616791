import React, { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { api, isset } from "../util/util.js";
import { useRecoilState } from "recoil";
import { currentMenuAtom, showLoaderAtom } from "../store/atom.js";
import {
  Alert,
  Container,
  Dialog,
  Fab,
  Grid,
  IconButton,
  Paper,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  Settings,
  Link as LinkIcon,
} from "@mui/icons-material";
import BranchEditor from "./BranchEditor.js";
import ConfirmDialog from "../component/ConfirmDialog.js";
import { API_CONNECTION, JA_LANG, SYSTEM_CONST } from "../config/Const.js";
import { enqueueSnackbar } from "notistack";
import UrlDialog from "../component/UrlDialog.js";

const columns = [
  { id: "name", label: "BRANCH", align: "left" },
  { id: "addr", label: "ADDRESS", align: "left" },
  { id: "phone", label: "TEL", align: "left" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Production() {
  const navigate = useNavigate();

  const loadData = useLoaderData().data;

  const [currentMenu, setCurrentMenu] = useRecoilState(currentMenuAtom);

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [productionList, setProductionList] = useState([]);

  const [editorOpen, setEditorOpen] = useState(false);
  const [urlOpen, setUrlOpen] = useState(false);

  const [urlInfos, setUrlInfos] = useState([]);

  //複数対象に対応するためIDで持つ
  const [showConfirm, setShowConfirm] = useState(0);

  const [editBranchId, setEditBranchId] = useState(0);
  const [proNameEdit, setProNameEdit] = useState([]);

  const [listData, setListData] = useState({});

  const [formData, setFormData] = useState({});

  const [errorList, setErrorList] = useState({});

  const useEffectInit = useRef(false);

  //初期処理
  useEffect(() => {
    setCurrentMenu("production");
    setProductionList(loadData.productions);
    //初期でやらないと初期値入らない
    initInputData(loadData.productions);
    initListData(loadData);
  }, []);

  //リスト更新時
  useEffect(() => {
    initInputData(productionList);
  }, [productionList]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const initInputData = (productions) => {
    const addInputData = {};

    productions.map((production) => {
      addInputData["production_name_" + production.id] = production.name;
    });

    if (addInputData.length !== 0) {
      setFormData({ ...formData, ...addInputData });
    }
  };

  const handleEditorOpen = (branchId) => {
    setEditBranchId(branchId);
    setEditorOpen(true);
  };

  const handleEditorClose = () => {
    setEditorOpen(false);
  };

  const handlePronameEdit = (productionId) => {
    setProNameEdit([...proNameEdit, productionId]);
  };

  const handlePronameEditClose = (productionId) => {
    setProNameEdit(proNameEdit.filter((pid) => pid !== productionId));
  };

  const handleChangePage = (event) => {
    api({ url: `${API_CONNECTION.URL}api/productions` })
      .then((res) => {
        setProductionList(res.data.productions);
      })
      .catch((err) => {});
  };

  const handleEdit = (event, productionId) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});

    setShowLoader(true);

    api({
      url: `${API_CONNECTION.URL}api/productions/name/${productionId}`,
      type: "put",
      data: {
        id: productionId,
        name: formData["production_name_" + productionId],
      },
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          handlePronameEditClose(productionId);
          handleChangePage(null);
          enqueueSnackbar("Production update successfully!", {
            variant: "success",
          });
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleDelete = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    const productionId = showConfirm;

    setErrorList({});
    setShowConfirm(0);

    setShowLoader(true);

    api({
      url: `${API_CONNECTION.URL}api/productions/${productionId}`,
      type: "delete",
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          handleChangePage(null);
          enqueueSnackbar("Production delete successfully!", {
            variant: "success",
          });
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
        <h2>Production</h2>
        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          sx={{ position: "absolute", top: 80, right: 15 }}
          onClick={() => {
            handleEditorOpen(0);
          }}
        >
          <Add />
        </Fab>
        <Grid container spacing={3}>
          {productionList.map((production) => (
            <Grid item xs={12} md={6} key={production.id}>
              <Paper sx={{ width: "100%" }} style={{ padding: "15px" }}>
                <Stack my={2} direction="row" spacing={1}>
                  <Typography
                    sx={{ mt: 2, mb: 2 }}
                    variant="h6"
                    component="div"
                  >
                    {proNameEdit.indexOf(production.id) !== -1 ? (
                      <>
                        <TextField
                          required
                          id={"production_name_" + production.id}
                          label="Production Name"
                          name={"production_name_" + production.id}
                          value={formData["production_name_" + production.id]}
                          onChange={handleForm}
                          sx={{ mr: "10px" }}
                        />
                        <IconButton
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          size="small"
                          color="secondary"
                          onClick={(e) => {
                            handleEdit(e, production.id);
                          }}
                          sx={{ textAlign: "center", padding: "0", mr: "10px" }}
                        >
                          <Check />
                        </IconButton>
                        <IconButton
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          size="small"
                          onClick={(e) => {
                            handlePronameEditClose(production.id);
                          }}
                          sx={{ textAlign: "center", padding: "0" }}
                        >
                          <Close />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <span style={{ marginRight: "10px" }}>
                          {production.name}
                        </span>
                        <IconButton
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          size="small"
                          onClick={(e) => {
                            handlePronameEdit(production.id);
                          }}
                          sx={{ textAlign: "center", padding: "0", mr: "10px" }}
                        >
                          <Edit />
                        </IconButton>
                        {production.production_branch.length < 1 && (
                          <IconButton
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            size="small"
                            onClick={(e) => {
                              // handleDelete(e, production.id);

                              setShowConfirm(production.id);
                            }}
                            sx={{ textAlign: "center", padding: "0" }}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Typography>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList["name_" + production.id])
                        ? ""
                        : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.production_name}
                  </Alert>
                </Stack>

                <TableContainer sx={{ maxHeight: window.innerHeight }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ fontWeight: "bold" }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell key="edit" align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {production.production_branch.map((production_branch) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            key={production_branch.id}
                          >
                            {columns.map((column) => {
                              const value =
                                column.id === "addr"
                                  ? "〒" +
                                    production_branch.zip +
                                    " " +
                                    (isset(listData.arrPref) &&
                                    isset(
                                      listData.arrPref[production_branch.pref]
                                    )
                                      ? listData.arrPref[production_branch.pref]
                                      : "") +
                                    production_branch.addr01 +
                                    " " +
                                    production_branch.addr02 +
                                    (production_branch.addr03
                                      ? " " + production_branch.addr03
                                      : "")
                                  : production_branch[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                            <TableCell key="edit" align="left">
                              <IconButton
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                size="small"
                                onClick={(e) => {
                                  handleEditorOpen(production_branch.id);
                                }}
                                sx={{ textAlign: "center", padding: "0" }}
                              >
                                <Settings />
                              </IconButton>
                              <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                size="small"
                                onClick={(e) => {
                                  setUrlInfos([
                                    {
                                      url: SYSTEM_CONST.USER_FRONT_URL + 'cre/entry/' + production_branch.url_code,
                                      label: "Regist URL",
                                    },
                                  ]);
                                  setUrlOpen(true);
                                }}
                                sx={{ textAlign: "center", padding: "0" }}
                              >
                                <LinkIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Dialog
        fullScreen
        open={editorOpen}
        onClose={handleEditorClose}
        TransitionComponent={Transition}
      >
        <BranchEditor
          closeFunc={handleEditorClose}
          branchId={editBranchId}
          key={editBranchId} //子を初期化させるために必要
          handleChangePage={handleChangePage}
        />
      </Dialog>
      <ConfirmDialog
        open={showConfirm ? true : false}
        setOpen={setShowConfirm}
        okFunc={(e) => {
          handleDelete(e);
        }}
        titleText={JA_LANG.CONFIRM_DELETE_TITLE}
      />
      <UrlDialog
        open={urlOpen}
        setOpen={setUrlOpen}
        title="Branch registration URL"
        urlInfos={urlInfos}
      />
    </>
  );
}

export default Production;
